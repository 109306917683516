<!-- [+] Show and filter Workers. -->
<template>
<div class="fit choose-worker-container">
    <div>
        <div class="position-relative p-0 no-focus-style">
            <q-item class="full-width align-items-center" clickable @click="toggleWorkers()">
                <q-item-section avatar>
                    <q-avatar class="cursor-pointer shadow-2">
                        <img v-if="selected_worker && selected_worker.image_link" :src="selected_worker.image_link">
                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                    </q-avatar>
                </q-item-section>
                <q-item-section>
                    <span class="text-muted">{{translate('selected_worker')}}</span>
                    <h4 class="mb-0 align-self-start cursor-pointer selected-customer-name">{{ selected_worker && selected_worker.name ? selected_worker.name : translate('choose_worker') }}</h4>
                </q-item-section>
                <q-item-section v-if="selected_worker && selected_worker.name" side>
                    <q-btn stack flat @click.stop="cleanSelection()" :title="translate('cancel_selection')">
                        <i class="fas fa-close"></i>
                    </q-btn>
                </q-item-section>
                <slot name="save_button"></slot>
            </q-item>
            <div v-if="showWorkers" class="dropdown-container">
            <q-separator />
            <div class="row m-0 align-items-center">
                <div class="col-xs-12  text-right">
                    <q-input class="search-input" @input="isTypingWorker = true" v-model="searchWorker" id="filternameworker" type="text" :placeholder="translate('find_worker')" :dense="true">
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </div>
            </div>
            <b-card-body class="card_scroll-workers bg-light m-0 pt-0 pb-0">
                <div class="list_wrapper-choose-worker">
                    <q-item class="full-width list-item" :class="selected_worker && selected_worker.name && worker.name === selected_worker.name ? 'bg-primary' : ''" tag="label" clickable v-ripple v-for="(worker,i) in workers" :key="'worker-' + i + '-' + worker.user_id">
                        <q-item-section avatar>
                            <q-avatar class="cursor-pointer shadow-2">
                                <img v-if="worker.image_link" :src="worker.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ worker.name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-radio emit-value @input="setSelectedWorker(worker.user_id, worker.name)" size="md" flat color="primary" :id="worker.user_id" :val="worker" v-model="selected_worker" />
                        </q-item-section>
                    </q-item>
                </div>
                <infinite-loading :identifier="infWorkers" slot="append" @infinite="getWorkers" />
            </b-card-body>
            </div>
        </div>
    </div>
</div>
</template>
<!-- [-] Show and filter Workers. -->

<script>
import { eventBus } from '../../../main'
import Vue from 'vue'

export default {
    name: 'ChooseWorker',
    watch: {
        searchWorker: _.debounce(function () {
            this.isTypingWorker = false
        }, 200),
        isTypingWorker: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    created: function () {
        eventBus.$on('cancelSelection', () => {
            this.cancelSelection()
        })
    },
    data: function () {
        return {
            showWorkers: false,
            state: {},
            infWorkers: +new Date(),
            workers: [],
            worker_options: [],
            page_worker: 1,
            isTypingWorker: false,
            searchWorker: '',
            selected_worker: {
                name: ''
            },
            schedule: {},
        }
    },
    methods: {
        cleanSelection: function () {
            this.selected_worker=null
            this.$emit('updateWorker', {'id': 0, 'name': ''})
            eventBus.$emit('updateWorker', {'id': 0, 'name': ''})
        },
        cancelSelection: function () {
            this.selected_worker = null
        },
        toggleWorkers: function () {
            this.showWorkers = !this.showWorkers
            this.resetMainList()
        },
        hideWorkers: function () {
            this.showWorkers = false
        },
        resetMainList: function () {
            this.page_worker = 1
            this.workers = []
            this.infWorkers++
        },
        setSelectedWorker: function (id, name) {
            this.selected_worker.user_id = id
            this.selected_worker.name = name
            this.$emit('updateWorker', {'user_id': id, 'name': name})
            eventBus.$emit('updateWorker', {'user_id': id, 'name': name})
            this.showWorkers = false
            // return false
        },
        getWorkers: function ($state) {
            var url = baseUrl +
                'rights/workers?page=' + this.page_worker +
                '&items_per_page=' + this.$items_per_page +
                (this.searchWorker ? '&search=' + this.searchWorker : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var options = []
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let workers = this.workers
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        options = response.data.items.map(entity => {
                            let array = {label: entity.name, value: entity.user_id}
                            return array
                        })
                        this.worker_options.push(...options)
                        workers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_worker++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.workers = workers
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        workerId: function () {
            var id = 0
            if (this.selected_worker && this.selected_worker.user_id) {
                id = this.selected_worker.user_id
            }
            return id
        },
    }
}
</script>

<style lang="scss" scoped>
.dropdown-container {
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 1000;
    background-color: #fff;
}
.card_scroll-workers {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    &.selected-worker {
        border-color: var(--primary)!important;
    }
}
body .search-input {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
}
</style>
