<template>
<div>
    <b-form @submit.prevent="edit">
        <b-card class="mb-2" no-body>
            <div slot="header">
                <h3>{{ translate('role_for_user') }}</h3>
            </div>
            <q-item class="full-width p-0">
                <choose-worker @updateWorker="getSelectedWorkerRoles">
                    <q-item-section class="mobile-hidden" slot="save_button" side>
                        <q-btn :disabled="!selected" v-if="!$q.screen.lt.sm" @click.stop.prevent="edit()" type="button" no-caps color="success">
                            <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                        </q-btn>
                    </q-item-section>
                 </choose-worker>
            </q-item>
        </b-card>
        <template v-if="!selected">
            <b-card no-header class="p-0 mb-2 text-muted">
                {{ translate('select_a_worker_to_see_and_setup_roles_for') }}
            </b-card>
        </template>
        <template v-if="selected">
            <b-card no-body no-header class="p-0 mb-2">
                <div class="header">
                    <q-item class="full-width m-0">
                        <q-item-section>
                            <h5 class="m-0 p-0">{{ translate('roles') }}</h5>
                        </q-item-section>
                        <q-item-section side>
                            <q-checkbox v-model="all_selected" @input="checkAll()" />
                        </q-item-section>
                    </q-item>
                    <q-separator />
                </div>
                <div class="body">
                    <q-item border clickable v-ripple tag="label" class="full-width m-0" v-for="(role,key) in roles" :key="'role-' + key + '-' + (role.id, key.id)">
                        <q-item-section>
                            <q-item-label>{{ role }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-checkbox :indeterminate-value="null" emit-value @input="setSelectedWorkerRoles()" :class="role" :id="'role-checkbox-' + key" v-model="selected_worker_roles" :val="key" />
                        </q-item-section>
                    </q-item>
                </div>
            </b-card>
            <q-item class="full-width p-0 mb-2">
                <q-item-section class="pr-0 pl-0" side>
                    <b-btn :disabled="!selected" variant="success" @click="edit()">
                        <q-icon size="1rem" name="check" /> {{ translate('save') }}</b-btn>
                </q-item-section>
                <q-space />
                <q-item-section class="pr-0 pl-0" side>
                    <b-btn variant="secondary" @click="resetAll()">
                        <q-icon size="1rem" name="cancel" /> {{ translate('cancel') }}</b-btn>
                </q-item-section>
            </q-item>
        </template>
    </b-form>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../main'
import ChooseWorker from './forms/ChooseWorker'

export default {
    name: 'RolesForUser',
    data: function () {
        return {
            selected: null,
            all_selected: false,
            selected_worker_roles: [],
            roles: [],
            worker_roles: [],
            dropdown_options: [],
            page: 1,
        }
    },
    components: {
        'choose-worker': ChooseWorker,
    },
    mounted: function () {
        eventBus.$on('updateWorker', (selected) => {
            this.getSelectedWorkerRoles(selected)
        })
    },
    created: function () {
        this.getRoles()
    },
    methods: {
        resetAll() {
            this.selected = null
            this.all_selected = false
            this.selected_worker_roles = []
            eventBus.$emit('cancelSelection')
        },
        checkAll: function () {
            this.setSelectedWorkerRoles()
            if (this.all_selected) {
                this.all_selected = false
                this.selected_worker_roles = []
            } else {
                this.all_selected = true
                for (let [key, role] of Object.entries(this.roles)) {
                    var in_array = this.allKeysInArray(key, this.selected_worker_roles)
                    if (!in_array) {
                        this.selected_worker_roles.push(key)
                    }
                }
            }
            return false
        },
        getRoles: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            };
            axios.get(baseUrl + 'rights/roles', {
                    headers: headers
                })
                .then(response => {
                    this.roles = response.data.items
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        resetSelectedWorkerRoles: function () {
            this.selected_worker_roles = []
        },
        getSelectedWorkerRoles(selected) {
            this.selected = selected
            if (selected.user_id == 0) {
                this.selected = null
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(baseUrl + 'rights/roles-for-user/' + selected.user_id, {
                    headers: headers
                })
                .then(response => {
                    this.resetSelectedWorkerRoles()
                    if (response.data.items) {
                        for (let [key, item] of Object.entries(response.data.items)) {
                            this.selected_worker_roles.push(key)
                        }
                        this.setSelectedWorkerRoles()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setSelectedWorkerRoles: function () {
            var temp_array = []
            var all_selected = true
            for (let [key, role] of Object.entries(this.roles)) {
                var in_array = this.allKeysInArray(key, this.selected_worker_roles)
                if (!in_array) {
                  all_selected = false
                }
            }
            this.all_selected = all_selected
        },
        allKeysInArray: function (array, inner_array) {
            var in_array = true
            for (let [key, feature] of Object.entries(array)) {
                if (!inner_array.includes(feature)) {
                    in_array = false
                    return false
                }
            }
            return in_array
        },
        edit: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var role_array = []
            role_array = this.selected_worker_roles.map(x => parseInt(x, 10))

            var data = {
                'user_id': parseInt(this.selected.user_id),
                'role_ids': role_array
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.put(baseUrl + 'rights/user-actor-role', data, {
                    headers: headers
                })
                .then(response => {
                    var message = 'edit_role_for_user_' + response.data.msg
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            response: data
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.input {
    float: right
}

body .dropdown-choose-role {
    margin-left: -15px;
    margin-right: -15px;

    *[class^="q-"].col.q-field__inner {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

@media screen and (min-width: 768px) {
    .dropdown-choose-role {
        width: calc(100% + 30px);
    }
}
</style>
